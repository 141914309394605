// hooks/useAddressSearch.js
import { useCallback } from 'react';
import { readAddresses } from '../services/apiEndPoints/addresses/readAddresses';

const useAddressSearch = () => {
  const onAddressSearch = useCallback(async(addressSearch) => {
    const userLocale = (navigator.language || 'FR').substring(0, 2);
    try {
      const response = await readAddresses({ addressSearch, country: userLocale });
      const result = Array.isArray(response.data) ? response.data : [];
      return result;
    } catch (error) {
      console.error('Error searching addresses:', error);
      return [];
    }
  }, []);

  return onAddressSearch;
};

export default useAddressSearch;
