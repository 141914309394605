import { useMemo } from 'react';
import { DeliveryListTabNames, DeliveryStatusEnum } from '../enum/DeliveryStatus.ts';

const useDeliverySlotDisplayRules = (status: DeliveryStatusEnum, deliveriesListTab: DeliveryListTabNames) => {
  const showPickupTime = useMemo(() => [
    DeliveryListTabNames.Schedule,
    DeliveryListTabNames.InProgress,
  ].includes(deliveriesListTab), [deliveriesListTab]);

  const isPickupTimeBold = useMemo(() => [
    DeliveryStatusEnum.Available,
    DeliveryStatusEnum.Reserved,
  ].includes(status) && showPickupTime, [status]);

  const isDeliveryTimeBold = useMemo(() => [
    DeliveryStatusEnum.ToPickUpPoint,
    DeliveryStatusEnum.AtPickUpPoint,
    DeliveryStatusEnum.ToRecipient,
  ].includes(status) && showPickupTime, [status]);

  return { showPickupTime, isPickupTimeBold, isDeliveryTimeBold };
};

export default useDeliverySlotDisplayRules;
