/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext } from 'react';
import { Col, Form, Row, Tabs } from 'antd';
import UserDeliveriesList from './Recipient/UserDeliveriesList';
import UserDeliveriesListMobile from './Recipient/UserDeliveriesListMobile';
import UserGeneralInformations from './UserGloblaInformation/UserGeneralInformations';
import ShopperAcount from './Shopper/ShopperAcount';
import ShopperDeliveriesList from './Shopper/ShopperDeliveriesList';
import ShopperDeliveriesListMobile from './Shopper/ShopperDeliveriesListMobile';
import { useRouter } from '../../hooks/useRouter';
import { useTranslation } from 'react-i18next';
import TouchableView from '../DeviceDetect/TouchableView';
import UntouchableView from '../DeviceDetect/UntouchableView';
import StatusErrorWrapper from '../Wrapper/StatusErrorWrapper';
import { useNavigate } from 'react-router';
import BreadcrumbContext from '../../context/BreadcrumbContext';
import parseInternalCommentBoV1 from '../../utils/parseInternalCommentBoV1';
import CallsToActions from './CallsToActions/CallsToActions';
import {
  readUser,
  readUserDocuments,
  readUserDeactivationReasons,
} from '../../services/apiEndPoints/user/readUser';
import ShopperCanceledDeliveriesList from './Shopper/ShopperCanceledDeliveriesList';
import ShopperCanceledDeliveriesListMobile from './Shopper/ShopperCanceledDeliveriesListMobile';
import LabelBadgeCounter from '../LabelBadgeCounter/LabelBadgeCounter';
import TabbedCard from '../TabbedCard/TabbedCard';
import { readUserAddresses } from '../../services/apiEndPoints/addresses/userAddress/readUserAddresses';
import { RecipientDeliveryAddress } from '@shopopop/backoffice-ui';
import { useTransformElevatorData } from '@shopopop/react-hooks';
import addressFormatter from '../../utils/addressFormatter';
import { formatElevatorFormDataToApi, generateAddressObject } from '@shopopop/backoffice-frontend-utils';
import { updateUserAddress } from '../../services/apiEndPoints/addresses/userAddress/updateUserAddress';
import { NotificationCenterContext } from '../../context/NotificationCenterContext';
import useAddressSearch from '../../hooks/useAddressSearch';

/**
 * User Component
 * @return {JSX.Element}
 */
function User() {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  const userId = router.query.id;
  const { setInfoBreadcrumb } = useContext(BreadcrumbContext);
  const notification = useContext(NotificationCenterContext);

  const [loading, setLoading] = useState(false);
  const [statusError, setStatusError] = useState(202);
  const [activeTab, setActiveTab] = useState(router.query.tab);
  const [deliveriesActiveTab, setDeliveriesActiveTab] = useState('deliveries-tab');
  const [userDeactivationReasons, setUserDeactivationReasons] = useState(null);
  const [disabledBanButton, setDisabledBanButton] = useState(false);
  const [isBanUser, setIsBanUser] = useState(false);
  const [user, setUser] = useState(null);
  const [kycView, setKycView] = useState([]);
  const [kycListDatabase, setKycListDatabase] = useState([]);
  const [deliveriesCount, setDeliveriesCount] = useState(0);
  const [address, setAddress] = useState(null);
  const [placeId, setPlaceId] = useState(null);

  const [addressFormChanged, setAddressFormChanged] = useState(false);

  const { transformedElevatorData } = useTransformElevatorData();

  const onClickTab = (key) => {
    setActiveTab(key);
    navigate(`/users/${router.query.id}?tab=${key}`);
  };

  const onAddressSearch = useAddressSearch();

  const onAddressSelect = ({ address, placeId }) => {
    setAddressFormChanged(true);
    setPlaceId(placeId);
    setAddress((prevAddress) => ({
      ...prevAddress,
      original_address: address,
    })
    );
  };

  useEffect(() => {
    if (!user) getUser();
    if (user) {
      readUserAddresses({ userId }).then((response) => {
        const [storedDeliveryAddress] = response.data; // On ne garde que la première adresse
        if (storedDeliveryAddress) {
          setAddress({
            id: storedDeliveryAddress.id,
            original_address: storedDeliveryAddress.original_address,
            address: addressFormatter({
              address: storedDeliveryAddress.address,
              zip_code: storedDeliveryAddress.zip_code,
              city: storedDeliveryAddress.city,
            }),
            elevator: transformedElevatorData(storedDeliveryAddress.elevator),
            floor: storedDeliveryAddress.floor,
            additional_info: storedDeliveryAddress.additional_info,
          });
        }
      });
    }
  }, [user]);

  const getUser = () => {
    setLoading(true);
    readUser({
      userId: router.query.id,
    }).then((response) => {
      if (response.data) {
        setUser(response.data);
        setInfoBreadcrumb(response.data);
        getKyc({ user: response.data });
        getUserDeactivationReasons();
      }
      setStatusError(response.status);
      setLoading(false);
    });
  };

  const getUserDeactivationReasons = () => {
    setLoading(true);
    readUserDeactivationReasons({
      userId: router.query.id,
    }).then((response) => {
      setUserDeactivationReasons(response.data);
      setIsBanUser(!!response.data);
      setLoading(false);
    });
  };

  const getKyc = ({ user }) => {
    const identityBelgium = (nationalIdData) => {
      if (nationalIdData.status === null) {
        return { type: 'national_id', status: 'NOT_SENT' };
      }
      return { type: 'national_id', status: 'VALIDATED' };
    };

    const identityCardStatus = (documentStatusArray) => {
      const statusOnfido = documentStatusArray.find(
        (doc) => doc.type === 'id_card_shopopop'
      );
      const statusMango = documentStatusArray.find(
        (doc) => doc.type === 'id_card_mango'
      );
      return [
        ...documentStatusArray,
        {
          type: 'identity_card',
          status:
              statusOnfido?.status === 'VALIDATED' ?
                statusMango?.status :
                statusOnfido?.status,
        },
      ];
    };

    readUserDocuments({ userId: router.query.id }).then((response) => {
      let KycBuffer = [];
      const userDocumentStatus = response.data;
      for (const property in userDocumentStatus) {
        if (Object.prototype.hasOwnProperty.call(userDocumentStatus, property)) {
          KycBuffer.push({ type: property, status: userDocumentStatus[property] });
        }
      }

      const nationalIdData = KycBuffer.find((kyc) => kyc.type === 'national_id');
      if (nationalIdData) {
        const indexNationalId = KycBuffer.findIndex(
          (element) => element.type === 'national_id'
        );
        KycBuffer = [
          ...KycBuffer.slice(0, indexNationalId),
          identityBelgium(nationalIdData),
          ...KycBuffer.slice(indexNationalId + 1),
        ];
      }

      KycBuffer = [
        ...identityCardStatus(KycBuffer),
        {
          type: 'phone_verified',
          status: user.phone_verified === 1 ? 'VALIDATED' : 'UNVALIDATED',
        },
      ];

      setKycListDatabase(KycBuffer);

      KycBuffer = KycBuffer.filter((d) => !d.type.includes('id_card'));
      setKycView(KycBuffer);
    });
  };

  const switchTab = (tabKey) => {
    setDeliveriesActiveTab(tabKey);
    setDeliveriesCount(0);
  };

  const [form] = Form.useForm();

  const updateRecipientAddress = async(newAddress) => {
    const addressData = generateAddressObject({
      addressFormValues: {
        ...newAddress,
        elevator: formatElevatorFormDataToApi(newAddress.elevator),
      },
      addressInitialValues: {
        ...address,
        elevator: formatElevatorFormDataToApi(address.elevator),
      },
      addressPlaceId: placeId,
    });

    await updateUserAddress({
      userId: userId,
      data: addressData,
    }).then((response) => {
      setAddress({
        address: addressFormatter({
          address: response.data.address,
          zip_code: response.data.zip_code,
          city: response.data.city,
        }),
        original_address: response.data.original_address,
        elevator: transformedElevatorData(response.data.elevator),
        floor: response.data.floor,
        additional_info: response.data.additional_info,
      });
      setAddressFormChanged(false);
      notification.push('success', t('ADDRESS_UPDATED'));
    });
  };

  const addressFormValuesChanged = (changedValues) => {
    const keys = Object.keys(changedValues);
    if (keys.includes('elevator') || keys.includes('floor') || keys.includes('comment')) {
      setAddressFormChanged(true);
    }
  };

  const tabsItems = [
    {
      label: t('USER_SHOPPER'),
      key: 'deliverer',
      id: 'users-tab-pane_shopper',
      children: (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ShopperAcount kycView={kycView} kycListDatabase={kycListDatabase} />
          </Col>
          <Col span={24}>
            <TouchableView>
              <TabbedCard>
                <Tabs
                  type="card"
                  animated
                  destroyInactiveTabPane
                  onTabClick={(key) => setDeliveriesActiveTab(key)}
                  items={[
                    {
                      key: 'deliveries-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('DELIVERIES')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'deliveries-tab'}
                        />
                      ),
                      children: (
                        <ShopperDeliveriesListMobile
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                          statusError={statusError}
                        />
                      ),
                    },
                    {
                      key: 'cancelation-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('CANCELATIONS')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'cancelation-tab'}
                        />
                      ),
                      children: (
                        <ShopperCanceledDeliveriesListMobile
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                          statusError={statusError}
                        />
                      ),
                    },
                  ]}
                />
              </TabbedCard>
            </TouchableView>
            <UntouchableView>
              <TabbedCard>
                <Tabs
                  type="card"
                  animated
                  destroyInactiveTabPane
                  onTabClick={switchTab}
                  items={[
                    {
                      key: 'deliveries-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('DELIVERIES')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'deliveries-tab'}
                        />
                      ),
                      children: (
                        <ShopperDeliveriesList
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                        />
                      ),
                    },
                    {
                      key: 'cancelation-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('CANCELATIONS')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'cancelation-tab'}
                        />
                      ),
                      children: (
                        <ShopperCanceledDeliveriesList
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                        />
                      ),
                    },
                  ]}
                />
              </TabbedCard>
            </UntouchableView>
          </Col>
        </Row>
      ),
    },
    {
      label: t('USER_CUSTOMER'),
      key: 'recipient',
      id: 'users-tab-pane-customer',
      children: (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <TouchableView>
              <UserDeliveriesListMobile statusError={statusError} />
            </TouchableView>
            <UntouchableView>
              <UserDeliveriesList statusError={statusError} />
            </UntouchableView>
          </Col>
          <Col span={24}>
            <Form
              form={form}
              layout='vertical'
              onFinish={(values) => updateRecipientAddress(values)}
              initialValues={{
                address: address?.address,
                elevator: address?.elevator,
                floor: address?.floor,
                comment: address?.additional_info,
              }}
              onValuesChange={addressFormValuesChanged}
            >
              <RecipientDeliveryAddress
                form={form}
                submitDisabled={!addressFormChanged}
                original_address={address?.address}
                elevator={transformedElevatorData(address?.elevator)}
                floor={address?.floor}
                additional_info={address?.additional_info}
                onAddressSearch={onAddressSearch}
                onAddressSelect={onAddressSelect}
                submitButton={{
                  disabled: !addressFormChanged,
                  visible: true,
                }}
                showOriginalAddress={address ? address.original_address || '-' : null}
              />
            </Form>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <StatusErrorWrapper statusError={statusError}>
      <div className="dual-panel-wrapper">
        <TouchableView>
          <CallsToActions
            setUserDeactivationReasons={setUserDeactivationReasons}
            userDeactivationReasons={userDeactivationReasons}
            disabledBanButton={disabledBanButton}
            setDisabledBanButton={setDisabledBanButton}
            isBanUser={isBanUser}
            setIsBanUser={setIsBanUser}
            getUserDeactivationReasons={getUserDeactivationReasons}
            internalCommentBOV1={parseInternalCommentBoV1(user?.internal_comment)}
            mobile
          />
        </TouchableView>
        <div>
          <UserGeneralInformations
            user={user}
            setUser={setUser}
            userDeactivationReasons={userDeactivationReasons}
            disabledBanButton={disabledBanButton}
            isBanUser={isBanUser}
            kycListDatabase={kycListDatabase}
            loading={loading}
          />
        </div>
        <div css={{ marginTop: 30 }}>
          <Tabs
            items={tabsItems}
            animated
            tabBarExtraContent={
              <UntouchableView>
                <CallsToActions
                  setUserDeactivationReasons={setUserDeactivationReasons}
                  userDeactivationReasons={userDeactivationReasons}
                  disabledBanButton={disabledBanButton}
                  setDisabledBanButton={setDisabledBanButton}
                  isBanUser={isBanUser}
                  setIsBanUser={setIsBanUser}
                  getUserDeactivationReasons={getUserDeactivationReasons}
                  internalCommentBOV1={parseInternalCommentBoV1(user?.internal_comment)}
                />
              </UntouchableView>
            }
            onChange={onClickTab}
            defaultActiveKey={activeTab}
          />
        </div>
      </div>
    </StatusErrorWrapper>
  );
}

export default User;
