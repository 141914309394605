interface AddressFormValues {
  floor?: number;
  elevator?: boolean | null;
  comment?: string | null;
  address?: string;
}

interface AddressInitialValues {
  floor?: number | null;
  elevator?: boolean | null;
  additionalInfo?: string | null;
  address?: string | null;
}

interface NewAddress {
  floor: number | null;
  elevator: boolean | null;
  additional_info: string | null;
  originalAddress: string | null;
  placeId?: string | null;
}

function generateAddressObject({
  addressFormValues = {},
  addressInitialValues = {
    floor: null,
    elevator: null,
    additionalInfo: null,
    address: null,
  },
  addressPlaceId,
}: {
  addressFormValues?: AddressFormValues;
  addressInitialValues?: AddressInitialValues;
  addressPlaceId?: string;
}): Partial<NewAddress> {
  const address: Partial<NewAddress> = {};

  if (addressFormValues.floor !== undefined && addressFormValues.floor !== addressInitialValues.floor) {
    address.floor = addressFormValues.floor;
  }

  if (addressFormValues.elevator !== undefined && addressFormValues.elevator !== addressInitialValues.elevator) {
    address.elevator = addressFormValues.elevator;
  }

  if ((addressFormValues.comment !== undefined) && (addressFormValues.comment !== addressInitialValues.additionalInfo)) {
    address.additional_info = addressFormValues.comment;
  }

  if (addressPlaceId) {
    address.originalAddress = addressFormValues.address;
    address.placeId = addressPlaceId;
  }

  return address;
}

export default generateAddressObject;
