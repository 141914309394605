import escapeRegExp from 'lodash/escapeRegExp';

/**
 * @param {string} text
 * @param {string} highlightTag
 * @return {string}
 */
function HighlightText({ text, highlightTag }: { text: string, highlightTag: string }) {
  return (
    <span>
      {text.split(new RegExp(`(${escapeRegExp(highlightTag)})`, 'gi')).map((part: string, id: number) => {
        return (
          <span key={id}>
            {part.toLowerCase() === highlightTag.toLowerCase() ? <b style={{ color: 'var(--primary-color)' }}>{part}</b> : part}
          </span>
        );
      })}
    </span>
  );
}

export default HighlightText;
